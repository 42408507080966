
export default {
  props: ["slug"],
  data: () => ({
    qrDialogOpen: false,
  }),
  methods: {
    copyLink() {
      navigator.clipboard.writeText(`https://link.modoc.id/${this.slug}`);
      this.$notify.success({
        message: "Link berhasil disalin",
        offset: 50,
        duration: 3500,
      });
    },
  },
};
