import { render, staticRenderFns } from "./ContentExistanceHandler.vue?vue&type=template&id=0fe1d0ba&"
import script from "./ContentExistanceHandler.vue?vue&type=script&lang=js&"
export * from "./ContentExistanceHandler.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageLock: require('/mnt/volume_berlin_01/frontends/mobile-community/components/ImageLock.vue').default})
