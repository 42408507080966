
export default {
  props: ["isEmpty", "isLoading", "contentType"],
  data: () => ({
    emptyMessage: {
      discussion: {
        message:
          "Belum ada post apapun nih. Yuk jadi yang pertama untuk post disini!",
      },
      media: {
        message: "Yah, belum ada postingan media di komunitas ini",
      },
      member: {
        message: "Yah, belum ada member di komunitas ini",
      },
      listing: {
        message: "Yah, belum ada postingan listing di komunitas ini",
      },
    },
  }),
};
