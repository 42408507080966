
export default {
  props: {
    isPublic: {
      default: 1,
      type: Number,
    },
    communityID: {
      default: null,
    },
    communitySlug: {
      default: "",
      type: String,
    },
  },
};
