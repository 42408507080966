
export default {
  props: ["id", "is_pinned", "isActive", "userID"],
  data: () => ({
    isBroadcastDialogShow: false,
    isOwner: false,
    postMenu: [
      {
        icon: "bx-broadcast",
        text: "Broadcast",
        action: "broadcast",
        style: " text-thirdgray ",
        role: ["owner", "admin", "moderator"],
      },
      {
        icon: "bx-pin",
        text: "Pin this post",
        action: "pin",
        style: "text-thirdgray ",
        role: ["owner", "admin", "moderator"],
      },
      {
        icon: "bx-edit",
        text: "Edit",
        action: "edit",
        style: "text-thirdgray ",
        role: ["self"],
      },
      {
        icon: "bx-trash",
        text: "Delete post",
        action: "delete",
        style: "text-danger",
        role: ["self"],
      },
    ],
    filteredMenu: [],
    restrictDropdownShow: false,
  }),
  mounted() {
    this.postMenu[1].text = this.is_pinned ? "Lepaskan" : "Sematkan";
    if (
      this.$store.state.auth.user.member &&
      this.$store.state.auth.user.member.position !== "member"
    ) {
      this.filteredMenu.push(
        ...this.postMenu.filter((el) =>
          el.role.includes(this.$store.state.auth.user.member.position)
        )
      );
    }
    if (!this.isActive) this.restrictDropdownShow = true;
    else if (this.userID === this.$store.state.auth.user.myProfile.id) {
      this.filteredMenu.push(
        ...this.postMenu.filter((el) => el.role.includes("self"))
      );
    } else if (this.$store.state.auth.user.member.position === "member") {
      this.restrictDropdownShow = true;
    }
  },
  methods: {
    handleTest() {},
    handleDropdown(val) {
      switch (val) {
        case "broadcast":
          this.isBroadcastDialogShow = true;
          break;
        case "delete":
          this.$nuxt.$emit("onClickDelete", this.id, "discussion");
          // this.handleDelete();
          break;
        case "edit":
          window.open(
            "/mb/community/" + this.$route.params.id + "/post?edit=" + this.id,
            "_top"
          );
          break;
        case "pin":
          this.handlePin();
          break;
        default:
          break;
      }
    },
    async handleBroadcast() {
      this.$broadcastDiscussion(this.id)
        .then((response) => {
          if (response.status) {
            this.$notify.success({
              message: "Berhasil broadcast diskusi",
              offset: 100,
              duration: 3500,
            });
            this.isBroadcastDialogShow = false;
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: "gagal broadcast diskusi",
            offset: 100,
            duration: 3500,
          });
          this.isBroadcastDialogShow = false;
        });
    },
    async handlePin() {
      this.$pinDiscussion(this.id, this.is_pinned)
        .then((response) => {
          if (response.status) {
            this.$notify.success({
              message: `Berhasil ${
                this.is_pinned ? "melepaskan" : "menyematkan"
              } diskusi`,
              offset: 100,
              duration: 1500,
              onClose: () => {
                window.location.reload();
              },
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: `Jumlah diskusi yang dipin sudah mencapai batas`,
            offset: 100,
            duration: 1500,
          });
        });
    },
    handleDelete() {
      this.$deleteDiscussion(this.id)
        .then((response) => {
          if (response.status) {
            this.$notify.success({
              message: `Berhasil menghapus diskusi`,
              offset: 100,
              duration: 1500,
              onClose: () => {
                window.location.reload();
              },
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: `Gagal menghapus diskusi`,
            offset: 100,
            duration: 1500,
          });
        });
    },
  },
};
