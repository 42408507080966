
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      image: "/mb/img/listing-img.png",
    };
  },
  methods: {},
};
